<template>
  <div class="detailed-table" v-if="columns.length > 0">
    <vxe-toolbar>
      <template #buttons>
        <el-link type="primary" icon="el-icon-plus" @click="selectType('policy')"
          v-show="addType === 'customer' || addType === 'org'">选择实用政策</el-link
        >
        <el-link type="primary" icon="el-icon-plus" @click="selectType('cus')"
          v-show="addType === 'policy'">选择实用客户</el-link
        >
        <el-link type="primary" icon="el-icon-plus" @click="selectType('org')"
          v-show="addType === 'policy'">选择实用组织</el-link
        >
        <el-link class="text-red" icon="el-icon-delete" @click="clearTable"
          >清空</el-link
        >
      </template>
    </vxe-toolbar>

    <vxe-table
      ref="xTable"
      resizable
      show-overflow
      :data="value"
      :edit-config="{ trigger: 'click', mode: 'cell' }"
    >
      <vxe-table-column
        v-for="(item, k) in columns"
        :key="k"
        :field="item.field"
        align="center"
        :title="item.title"
        v-bind="itemRender(item, k)"
      ></vxe-table-column>
      <vxe-table-column fixed="right" title="操作" align="center" width="90">
        <template #default="{ rowIndex }">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeRow(rowIndex)"
          >
            <el-button class="text-red" slot="reference" type="text"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </vxe-table-column>
    </vxe-table>

    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '../../../../../utils/request';
import SelectConfig from '../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  components: {
    SelectConfig,
  },
  props: {
    value: Array,
    getValue: Function,
    endTime: String,
    addType: String,
  },
  data() {
    return {
      columns: [], // 标题数组
      functionCode: 'add_active_list',
      params: {}, // 弹框的参数
      checkRowData: [], // 选择行数据后回显到上个组件
    };
  },
  created() {
    this.getColumn(this.functionCode);
  },
  methods: {
    // 获取表格配置
    getColumn(functionCode) {
      const parentCode = this.parentCode
        ? this.parentCode
        : this.$store.state.menus.selectMenu;
      request
        .post('/mdm/mdmtableconfig/query', { functionCode, parentCode })
        .then((res) => {
          if (res.success) {
            const temparr = res.result.column.filter((v) => v.visible);
            if (this.addType === 'customer' || this.addType === 'org') {
              this.columns = temparr.filter((v) => (v.field !== 'orgCode' && v.field !== 'orgName' && v.field !== 'customerCode' && v.field !== 'customerName'));
            } else {
              this.columns = temparr.filter((v) => (v.field !== 'code' && v.field !== 'name'));
            }
          }
        });
    },
    // 点击三种新增方式
    selectType(str) {
      if (str === 'policy') {
        this.params = {
          functionCode: 'add_select_policy',
          title: '选择政策',
          noReset: true,
          paramData: {
            actEndDate: this.endTime,
          },
        };
      } else if (str === 'org') {
        this.params = {
          functionCode: 'add_select_org',
          title: '选择组织',
          noReset: true,
          paramData: {
            actEndDate: this.endTime,
          },
        };
      } else {
        this.params = {
          functionCode: 'add_select_customer',
          title: '选择客户',
          noReset: true,
          paramData: {
            actEndDate: this.endTime,
          },
        };
      }
      this.$refs.selectConfig.openSelectModal(this.params);
    },
    // 选择回调,根据添加的类型加入标记字段
    onGetSelect(val) {
      // console.log(val);
      let addTypeFlag = '';
      if (this.addType === 'customer') {
        addTypeFlag = 'byCustomer';
      } else if (this.addType === 'org') {
        addTypeFlag = 'byOrg';
      } else {
        addTypeFlag = 'byPolicy';
      }
      let temparr = [];
      val.map((v) => {
        const tempobj = {
          ...v,
          addTypeFlag,
          policyCode: v.code,
          policyName: v.name,
        };
        temparr.push(tempobj);
        return v;
      });
      // 如果是在编辑的时候新增，就连接两个数组
      if (this.value.length !== 0) {
        temparr = this.value.concat(temparr);
      }
      this.$emit('input', temparr);
    },
    // 清空表单数据
    clearTable() {
      if (this.value.length === 0) {
        return false;
      }
      this.$confirm('确认要清空表格数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$emit('input', []);
      }).catch(() => {});
    },
    // 删除
    removeRow(index) {
      this.value.splice(index, 1);
      this.$emit('input', this.value);
    },
    // 单元格渲染可编辑
    itemRender(item, k) {
      const { title, field } = item;
      let editRender = null;
      if (field === 'forecastSalesAmount') {
        editRender = {
          name: 'ElInputNumber',
          placeholder: `请输入${title}`,
          events: {
            change: ({ row, rowIndex }) => { this.calculation(field, row, rowIndex); },
          },
          props: {
            controls: false,
            clearable: true,
          },
        };
      }
      if (field === 'remarks') {
        editRender = {
          name: 'ElInput',
          placeholder: `请输入${title}`,
          events: {
            change: ({ row, rowIndex }) => {
              this.changeRow(field, row, rowIndex);
            },
          },
          props: {
            clearable: true,
          },
        };
      }
      return { editRender };
    },
    // 计算申请金额
    calculation(field, row, index) {
      const curRow = row;
      curRow.applyAmount = curRow.forecastSalesAmount * (curRow.rate ? curRow.rate : 0);
      this.changeRow(field, curRow, index);
    },
    changeRow(field, row, index) {
      const list = JSON.parse(JSON.stringify(this.value));
      list[index] = JSON.parse(JSON.stringify(row));
      this.$emit('input', list);
    },
  },
};
</script>

<style lang="less" scoped>
.detailed-table {
  .text-red {
    color: #f56c6c !important;
    margin: 0 5px;
  }
}
.el-link {
  margin: 0 10px;
}
</style>
