<template>
  <div class="share-table">
    <vxe-toolbar v-if="!disabled">
      <template #buttons>
        <el-link type="primary" @click="addFn">新增行</el-link>
        <el-link class="text-red" @click="clearTable">清空</el-link>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      resizable
      show-overflow
      :data="value"
      :edit-config="{trigger: 'click', mode: 'cell'}"
    >
      <vxe-table-column
        v-for="(item, k) in columns"
        :key="k"
        :min-width="120"
        v-bind="itemRender(item, k)"
      ></vxe-table-column>
      <vxe-table-column title="操作" align="center" width="80" v-if="!disabled">
        <template #default="{ rowIndex }">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeRow(rowIndex)"
          >
            <el-button class="text-red" slot="reference" type="text" icon="el-icon-delete"></el-button>
          </el-popconfirm>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import request from '../../../../utils/request';

export default {
  props: {
    value: Array,
    disabled: Boolean,
    getValue: Function,
  },
  data() {
    return {
      columns: [
        {
          field: 'productAndLevelCode',
          title: '产品',
          type: 'select',
        },
        {
          field: 'shareRatio',
          title: '产品比例（%）',
          type: 'input',
        },
        {
          field: 'remarks',
          title: '备注',
          type: 'input',
        },
      ],
      productList: [],
    };
  },
  created() {
    this.getProductList();
  },
  methods: {
    // 新增行
    addFn() {
      const list = JSON.parse(JSON.stringify(this.value || []));
      list.push({
        categoriesCode: this.getValue('categoriesCode'),
        productAndLevelCode: '',
        productAndLevelName: '',
        shareRatio: '',
        remarks: '',
        productShareType: '',
      });

      this.$emit('input', list);
    },
    // 清空表格
    clearTable() {
      if (this.value.length === 0) {
        return false;
      }
      this.$confirm('确认要清空表格数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('input', []);
        })
        .catch(() => {
          this.$message.info('已取消');
        });
    },
    // 删除
    removeRow(index) {
      const list = JSON.parse(JSON.stringify(this.value || []));
      const newList = list.filter((v, k) => k !== index);
      this.$emit('input', newList);
    },
    // 单元格渲染
    itemRender(item) {
      const { title, field, type } = item;
      let editRender = null;

      if (!this.disabled && type === 'select') {
        editRender = {
          name: 'ElSelect',
          options: this.productList,
          props: {
            placeholder: `请选择${title}`,
            clearable: true,
            filterable: true,
            remote: true,
            reserveKeyword: true,
            remoteMethod: this.getProductList,
            loading: this.$store.state.buttonApilsLoading,
          },
          events: {
            change: ({ row, rowIndex }) => {
              this.changeRow(field, row, rowIndex);
            },
          },
        };
      } else if (!this.disabled && type === 'input') {
        if (field === 'shareRatio') {
          editRender = {
            name: 'ElInputNumber',
            props: {
              placeholder: `请输入${title}`,
              clearable: true,
              controls: false,
              min: 0,
              max: 100,
              precision: 0,
              value: undefined,
            },
            events: {
              change: ({ row, rowIndex }) => {
                this.changeRow(field, row, rowIndex);
              },
            },
          };
        } else {
          editRender = {
            name: 'ElInput',
            props: {
              placeholder: `请输入${title}`,
              clearable: true,
            },
          };
        }
      }

      return {
        title,
        field,
        editRender,
      };
    },
    // 编辑监听
    changeRow(field, row, index) {
      let _item = null;
      const list = JSON.parse(JSON.stringify(this.value));

      if (field === 'productAndLevelCode') {
        _item = this.productList.find(
          (v) => v.value === row.productAndLevelCode,
        );
        list[index] = {
          ...list[index],
          productAndLevelName: _item ? _item.label : '',
          productShareType: _item ? _item.type : '',
        };
      }

      this.$emit('input', list);
    },
    // 获取产品列表
    getProductList(query) {
      const param = {};
      // 模糊查询
      if (query) {
        param.codeOrName = query;
      }

      request
        .post('/mdm/mdmProductController/findProductAndProductLevel', param)
        .then((res) => {
          if (res.success) {
            const list = res.result || [];
            this.productList = list.map((v) => ({
              ...v,
              label: v.productAndLevelName,
              value: v.productAndLevelCode,
            }));
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.share-table {
  padding-left: 50px;

  .text-red {
    color: #f56c6c !important;
  }
}
</style>
