import Form, { formCreate } from '../../../../components/form';
import addMethods from './addMethods/addMethods.vue';

formCreate.component('addMethods', addMethods); // 在form中注册

export default {
  extends: Form,
  props: {
    defaultValue: Object,
    params: Object,
  },
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      // 按钮
      buttons: {
        submit: true,
        close: true,
      },
      buttonText: {
        submit: '确定',
        close: '取消',
      },
      // 表单下拉框获取编码和名称
      selectData: {},
    };
  },
  async created() {
    const res = await this.getFormRule(this.params.formFunctionCode, this.formParentCode);
    if (this.params.value) {
      this.setValue({
        fineCode: this.params.value.fineCode,
        payType: this.params.value.payType,
        orgCode: this.params.value.orgCode,
        policyCode: this.params.value.policyCode,
        customerCode: this.params.value.customerCode,
      });
    }
  },
  methods: {
    // form表单点击关闭,调用父组件的方法关闭自定义弹出框
    closeModal() {
      this.$emit('close');
    },
    // 设置表单规则
    setRule(item) {
      const v = item;
      const { addType } = this.params;

      if (v.field === 'orgCode' || v.field === 'policyCode') {
        v.type = ((addType === 'customer') ? 'hidden' : v.type);
      }
      if (v.field === 'customerCode' || v.field === 'policyCode') {
        v.type = addType === 'org' ? 'hidden' : v.type;
      }
      if (v.field === 'orgCode' || v.field === 'customerCode') {
        v.type = addType === 'policy' ? 'hidden' : v.type;
        if (v.field === 'customerCode') {
          v.restful = '/mdm/mdmCustomerMsgController/findCustomerSelectList';
          v.restfulParams = { enableStatus: '009' };
          v.optionsKey = { label: 'customerName', value: 'customerCode' };
        } else {
          v.restful = '/mdm/mdmOrgController/select';
          v.restfulParams = { enableStatus: '009' };
          v.optionsKey = { label: 'orgName', value: 'orgCode' };
        }
      }

      if (v.field === 'policyCode') {
        v.restful = '/tpm/tpmActController/getRebatesPage';
        v.restfulParams = { enableStatus: '009', actEndDate: this.params.endTime };
        v.optionsKey = { label: 'policyName', value: 'policyCode' };
      }

      if (v.field === 'fineCode') {
        v.restful = '/tpm/tpmCostTypeFineController/list';
        v.restfulParams = { enableStatus: '009' };
        v.optionsKey = { label: 'fineName', value: 'fineCode' };
      }
      if (v.field === 'addActive') {
        v.props = {
          ...v.props,
          endTime: this.params.endTime,
          addType: this.params.addType,
        };
        if (this.params.value) {
          v.value = this.params.value.addActive;
        } else {
          v.value = [];
        }
      }

      return v;
    },

    // 表单渲染完成后回调
    formComplete() {
      const fineCode = this.getRule('fineCode');
      fineCode.on.getLabel = (e) => {
        this.selectData.fineName = e;
      };
      const payType = this.getRule('payType');
      payType.on.getLabel = (e) => {
        this.selectData.payTypeName = e;
      };
      const customerCode = this.getRule('customerCode');
      customerCode.on.getLabel = (e) => {
        this.selectData.customerName = e;
      };
      const orgCode = this.getRule('orgCode');
      orgCode.on.getLabel = (e) => {
        this.selectData.orgName = e;
      };
    },
    // 表单提交，回显到最外层表单组件中
    submit() {
      const formData = this.getFormData();
      if (this.params.value) {
        // 把修改过后的下拉选择数据赋值到默认名称对象中进行覆盖
        for (const key in this.params.value.tempselectobj) {
          if (Object.prototype.hasOwnProperty.call(this.params.value.tempselectobj, key)) {
            for (const v in this.selectData) {
              if (Object.prototype.hasOwnProperty.call(this.selectData, v)) {
                if (key === v) {
                  this.params.value.tempselectobj[key] = this.selectData[v];
                }
              }
            }
          }
        }
        this.selectData = { ...this.params.value.tempselectobj };
      }
      if (formData) {
        if (formData.addActive.length !== 0) {
          const temparr = [];
          formData.addActive.map((v) => {
            const tempobj = {
              ...v,
              ...this.selectData, // 下拉框的数据name值
              fineCode: formData.fineCode,
              payType: formData.payType,
              orgCode: v.orgCode ? v.orgCode : formData.orgCode,
              customerCode: v.customerCode ? v.customerCode : formData.customerCode,
              policyCode: v.policyCode ? v.policyCode : formData.policyCode,
            };
            temparr.push(tempobj);
            return v;
          });
          this.$emit('showback', temparr);
          this.closeModal();
        } else if (this.params.addType === 'customer' || this.params.addType === 'org') {
          this.$message.error('请至少选择一个政策');
        } else {
          this.$message.error('请至少选择一个客户或组织');
        }
      }
    },
  },
};
