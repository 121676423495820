<template>
  <div class="prepayment_detail">
    <vxe-toolbar v-if="!disabled">
      <template #buttons>
        <el-link type="primary" icon="el-icon-plus" @click="addFn">添加活动明细</el-link>
        <el-link type="primary" icon="el-icon-upload2">导入</el-link>
        <el-link class="text-red" icon="el-icon-delete" @click="clearTable">清空</el-link>
      </template>
    </vxe-toolbar>
    <div v-if="columns.length > 0">
      <vxe-table
        ref="xTable"
        resizable
        show-overflow
        show-footer
        :data="value"
        :edit-config="{ trigger: 'click', mode: 'cell' }"
        :footer-method="footerMethod"
      >
        <vxe-table-column
          v-for="(item, k) in columns"
          :key="k"
          :min-width="120"
          v-bind="itemRender(item, k)"
        >
          <template v-if="item.field === 'applyAdvanceAmount'" #edit="{ row }">
            <el-input-number
              v-model="row[item.field]"
              :placeholder="`请输入${item.title}`"
              clearable
              :controls="false"
              :min="0"
              :max="Number(row.applyAmount) - Number(row.alreadyApplyAdvanceTotalAmount)"
              :precision="2"
            ></el-input-number>
          </template>
        </vxe-table-column>
        <vxe-table-column fixed="right" title="操作" align="center" width="90" v-if="!disabled">
          <template #default="{ rowIndex }">
            <el-popconfirm
              title="此操作将永久删除该数据？"
              confirm-button-type="text"
              @confirm="removeRow(rowIndex)"
            >
              <el-button class="text-red" slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '../../../../utils/request';
import SelectConfig from '../../../../components/select_org_cus_ter/select_config.vue';

export default {
  props: {
    value: Array,
    disabled: Boolean,
    getValue: Function,
    parmas: Object,
    type: String,
  },
  data() {
    return {
      columns: [],
    };
  },
  components: {
    SelectConfig,
  },
  created() {
    this.getColumn();
  },
  methods: {
    // 获取表格配置
    getColumn() {
      request.post('/mdm/mdmtableconfig/query', this.parmas).then((res) => {
        if (res.success) {
          this.columns = res.result.column.filter((v) => v.visible);
        }
      });
    },
    // 单元格渲染
    itemRender(item) {
      const { title, field, type } = item;
      let column = {
        title,
        field,
      };
      let editRender = null;

      if (field === 'checkTyperadio' || field === 'checkTypecheckbox' || field === 'checkTypeseq') {
        column = {
          // type: field === 'checkTyperadio' ? 'radio' : 'checkbox',
          type: () => {
            if (field === 'checkTyperadio') {
              return 'radio';
            }
            if (field === 'checkTypecheckbox') {
              return 'checkbox';
            }
            if (field === 'checkTypeseq') {
              return 'seq';
            }
          },
          width: '50',
          fixed: 'left',
          align: 'center',
          visible: !this.disabled,
        };
      } else if (type === 'input' && field !== 'applyAdvanceAmount') {
        editRender = {
          name: 'ElInput',
          props: {
            placeholder: `请输入${title}`,
            clearable: true,
          },
        };
      } else if (type === 'input' && field === 'applyAdvanceAmount') {
        editRender = {
          name: 'ElInputNumber',
          props: {
            value: undefined,
          },
        };
      }

      return {
        ...column,
        editRender,
      };
    },
    // 统计
    footerMethod({ columns, data }) {
      if (data.length > 0) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '合计';
            }
            if (columnIndex === 8) {
              return `${this.sumNum(data, 'applyAdvanceAmount')}`;
            }
            return '';
          }),
        ];
      }
      return [];
    },
    sumNum(list, field) {
      let count = 0;
      list.forEach((item) => {
        count += Number(item[field] || 0);
      });
      return count;
    },
    // 添加列表
    addFn() {
      const code = this.getValue(this.type);
      if (!code) {
        this.$message.error(`请选择${this.type === 'customerCode' ? '客户' : '活动'}再进行操作！`);
      } else {
        const params = {
          functionCode: 'tpm_activity_detail_list',
          data: this.value,
          idKey: this.type,
          paramData: {
            advanceType: this.type === 'customerCode' ? 'customer' : 'act',
            [`${this.type}`]: code,
          },
        };
        this.$refs.selectConfig.openSelectModal(params);
      }
    },
    // 活动选择数据回调
    async onGetSelect(val) {
      // const list = val.map((v) => ({
      //   ...v,
      //   actDetailApplyAmount: v.applyAmount,
      //   alreadyAuditAmount: v.auditAmount,
      // }));

      this.$emit('input', val);
    },
    // 清空表格
    clearTable() {
      if (this.value.length === 0) {
        return false;
      }
      this.$confirm('确认要清空表格数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$emit('input', []);
        })
        .catch(() => {
          this.$message.info('已取消');
        });
    },
    // 删除
    removeRow(index) {
      const list = JSON.parse(JSON.stringify(this.value || []));
      const newList = list.filter((v, k) => k !== index);
      this.$emit('input', newList);
    },
  },
};
</script>

<style lang="less" scoped>
.prepayment_detail {
  padding-left: 50px;

  .text-red {
    color: #f56c6c !important;
  }

  /deep/.vxe-footer--row {
    background-color: #f5f7fa;
    color: #606266;
  }
}
</style>
