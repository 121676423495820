var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length > 0
    ? _c(
        "div",
        { staticClass: "detailed-table" },
        [
          _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.addActive("customer")
                            },
                          },
                        },
                        [_vm._v("按客户新增")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-document-copy",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addActive("org")
                            },
                          },
                        },
                        [_vm._v("按组织新增")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", icon: "el-icon-share" },
                          on: {
                            click: function ($event) {
                              return _vm.addActive("policy")
                            },
                          },
                        },
                        [_vm._v("按政策新增")]
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", icon: "el-icon-files" },
                          on: { click: _vm.upload },
                        },
                        [_vm._v("附件")]
                      ),
                      _c(
                        "el-link",
                        {
                          staticClass: "text-red",
                          attrs: { icon: "el-icon-delete" },
                          on: { click: _vm.clearTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2937274672
            ),
          }),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                align: "center",
                resizable: "",
                "show-overflow": "",
                "footer-span-method": _vm.footerColspanMethod,
                "footer-method": _vm.footerMethod,
                data: _vm.value,
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectChangeEvent,
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { type: "checkbox", align: "center", width: "40" },
              }),
              _vm._l(_vm.columns, function (item, k) {
                return _c("vxe-table-column", {
                  key: k,
                  attrs: {
                    field: item.field,
                    align: "center",
                    width: "100",
                    title: item.title,
                  },
                })
              }),
              _c("vxe-table-column", {
                attrs: {
                  fixed: "right",
                  title: "操作",
                  align: "center",
                  width: "90",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        var row = ref.row
                        return [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title: "此操作将永久删除该数据？",
                                "confirm-button-type": "text",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.removeRow(rowIndex)
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "text-red",
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { slot: "reference", type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.rowedit(rowIndex, row)
                                },
                              },
                              slot: "reference",
                            },
                            [_vm._v("编辑")]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2388530604
                ),
              }),
            ],
            2
          ),
          _c("UploadModal", {
            ref: "uploadModal",
            on: { onGetFileList: _vm.getFileList },
          }),
          _c(
            "Modal",
            {
              attrs: { modalConfig: _vm.modalConfig },
              on: { onClose: _vm.closeModal },
            },
            [
              _c(_vm.formName, {
                ref: "modalForm",
                tag: "component",
                attrs: { params: _vm.params },
                on: { close: _vm.closeModal, showback: _vm.showback },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }