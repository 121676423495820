var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detailed-table" },
    [
      _vm.columns.length > 0
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      !_vm.disabled
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary", icon: "el-icon-plus" },
                              on: { click: _vm.addFn },
                            },
                            [_vm._v("新增行")]
                          )
                        : _vm._e(),
                      !_vm.disabled
                        ? _c(
                            "el-link",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-document-copy",
                              },
                              on: { click: _vm.copyLine },
                            },
                            [_vm._v("复制行")]
                          )
                        : _vm._e(),
                      !_vm.disabled
                        ? _c(
                            "el-link",
                            {
                              attrs: { type: "primary", icon: "el-icon-share" },
                              on: { click: _vm.shareBudget },
                            },
                            [_vm._v("分摊")]
                          )
                        : _vm._e(),
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", icon: "el-icon-files" },
                          on: { click: _vm.upload },
                        },
                        [_vm._v("附件")]
                      ),
                      !_vm.disabled
                        ? _c(
                            "el-link",
                            {
                              staticClass: "text-red",
                              attrs: { icon: "el-icon-delete" },
                              on: { click: _vm.clearTable },
                            },
                            [_vm._v("清空")]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2489291022
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            resizable: "",
            "show-overflow": "",
            data: _vm.value,
            "edit-config": {
              trigger: "click",
              mode: "cell",
              activeMethod: _vm.activeCellMethod,
            },
          },
          on: {
            "edit-disabled": _vm.editDisabledEvent,
            "radio-change": _vm.radioChangeEvent,
          },
        },
        [
          _vm._l(_vm.columns, function (item, k) {
            return _c(
              "vxe-table-column",
              _vm._b(
                {
                  key: k,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(_vm._s(_vm.resetText(row, item.field))),
                            ]),
                          ]
                        },
                      },
                      item.field === "customerCode" ||
                      item.field === "terminalCode" ||
                      item.field === "giftCodes" ||
                      item.field === "productCodes"
                        ? {
                            key: "edit",
                            fn: function (ref) {
                              var row = ref.row
                              var rowIndex = ref.rowIndex
                              return [
                                item.field === "customerCode"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              placeholder:
                                                "请选择" + item.title,
                                              "remote-method": function (
                                                query
                                              ) {
                                                return _vm.remoteMethod(
                                                  item.field,
                                                  query,
                                                  rowIndex
                                                )
                                              },
                                              loading:
                                                _vm.$store.state
                                                  .buttonApilsLoading,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeRow(
                                                  item.field,
                                                  row,
                                                  rowIndex
                                                )
                                              },
                                            },
                                            model: {
                                              value: row[item.field],
                                              callback: function ($$v) {
                                                _vm.$set(row, item.field, $$v)
                                              },
                                              expression: "row[item.field]",
                                            },
                                          },
                                          _vm._l(
                                            _vm.customerList,
                                            function (v, k) {
                                              return _c("el-option", {
                                                key: k,
                                                attrs: {
                                                  label: v.customerName,
                                                  value: v.customerCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.field === "terminalCode"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              placeholder:
                                                "请选择" + item.title,
                                              "remote-method": function (
                                                query
                                              ) {
                                                return _vm.remoteMethod(
                                                  item.field,
                                                  query,
                                                  rowIndex
                                                )
                                              },
                                              loading:
                                                _vm.$store.state
                                                  .buttonApilsLoading,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeRow(
                                                  item.field,
                                                  row,
                                                  rowIndex
                                                )
                                              },
                                            },
                                            model: {
                                              value: row[item.field],
                                              callback: function ($$v) {
                                                _vm.$set(row, item.field, $$v)
                                              },
                                              expression: "row[item.field]",
                                            },
                                          },
                                          _vm._l(
                                            _vm.terminalList,
                                            function (v, k) {
                                              return _c("el-option", {
                                                key: k,
                                                attrs: {
                                                  label: v.terminalName,
                                                  value: v.terminalCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : item.field === "giftCodes" ||
                                    item.field === "productCodes"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              multiple: "",
                                              placeholder:
                                                "请选择" + item.title,
                                              "remote-method": function (
                                                query
                                              ) {
                                                return _vm.remoteMethod(
                                                  item.field,
                                                  query,
                                                  rowIndex
                                                )
                                              },
                                              loading:
                                                _vm.$store.state
                                                  .buttonApilsLoading,
                                              "collapse-tags": "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.changeRow(
                                                  item.field,
                                                  row,
                                                  rowIndex
                                                )
                                              },
                                            },
                                            model: {
                                              value: row[item.field],
                                              callback: function ($$v) {
                                                _vm.$set(row, item.field, $$v)
                                              },
                                              expression: "row[item.field]",
                                            },
                                          },
                                          _vm._l(
                                            _vm.productList,
                                            function (v, k) {
                                              return _c("el-option", {
                                                key: k,
                                                attrs: {
                                                  label: v.productName,
                                                  value: v.productCode,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                "vxe-table-column",
                _vm.itemRender(item),
                false
              )
            )
          }),
          !_vm.disabled && _vm.columns.length > 0
            ? _c("vxe-table-column", {
                attrs: {
                  fixed: "right",
                  title: "操作",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title: "此操作将永久删除该数据？",
                                "confirm-button-type": "text",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.removeRow(rowIndex)
                                },
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "text-red",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  icon: "el-icon-delete",
                                },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3242369890
                ),
              })
            : _vm._e(),
        ],
        2
      ),
      _c("UploadModal", {
        ref: "uploadModal",
        attrs: { disabled: _vm.disabled },
        on: { onGetFileList: _vm.getFileList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }