var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length > 0
    ? _c(
        "div",
        { staticClass: "detailed-table" },
        [
          _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.addType === "customer" ||
                                _vm.addType === "org",
                              expression:
                                "addType === 'customer' || addType === 'org'",
                            },
                          ],
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.selectType("policy")
                            },
                          },
                        },
                        [_vm._v("选择实用政策")]
                      ),
                      _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.addType === "policy",
                              expression: "addType === 'policy'",
                            },
                          ],
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.selectType("cus")
                            },
                          },
                        },
                        [_vm._v("选择实用客户")]
                      ),
                      _c(
                        "el-link",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.addType === "policy",
                              expression: "addType === 'policy'",
                            },
                          ],
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function ($event) {
                              return _vm.selectType("org")
                            },
                          },
                        },
                        [_vm._v("选择实用组织")]
                      ),
                      _c(
                        "el-link",
                        {
                          staticClass: "text-red",
                          attrs: { icon: "el-icon-delete" },
                          on: { click: _vm.clearTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2252567711
            ),
          }),
          _c(
            "vxe-table",
            {
              ref: "xTable",
              attrs: {
                resizable: "",
                "show-overflow": "",
                data: _vm.value,
                "edit-config": { trigger: "click", mode: "cell" },
              },
            },
            [
              _vm._l(_vm.columns, function (item, k) {
                return _c(
                  "vxe-table-column",
                  _vm._b(
                    {
                      key: k,
                      attrs: {
                        field: item.field,
                        align: "center",
                        title: item.title,
                      },
                    },
                    "vxe-table-column",
                    _vm.itemRender(item, k),
                    false
                  )
                )
              }),
              _c("vxe-table-column", {
                attrs: {
                  fixed: "right",
                  title: "操作",
                  align: "center",
                  width: "90",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title: "此操作将永久删除该数据？",
                                "confirm-button-type": "text",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.removeRow(rowIndex)
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "text-red",
                                  attrs: { slot: "reference", type: "text" },
                                  slot: "reference",
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  854281698
                ),
              }),
            ],
            2
          ),
          _c("SelectConfig", {
            ref: "selectConfig",
            on: { onGetSelect: _vm.onGetSelect },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }