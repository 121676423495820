<template>
  <div class="detailed-table" v-if="columns.length > 0">
    <vxe-toolbar>
      <template #buttons>
        <el-link
          type="primary"
          icon="el-icon-plus"
          @click="addActive('customer')"
          >按客户新增</el-link
        >
        <el-link
          type="primary"
          icon="el-icon-document-copy"
          @click="addActive('org')"
          >按组织新增</el-link
        >
        <el-link
          type="primary"
          icon="el-icon-share"
          @click="addActive('policy')"
          >按政策新增</el-link
        >
        <el-link type="primary" icon="el-icon-files" @click="upload"
          >附件</el-link
        >
        <el-link class="text-red" icon="el-icon-delete" @click="clearTable"
          >清空</el-link
        >
      </template>
    </vxe-toolbar>

    <vxe-table
      ref="xTable"
      align="center"
      resizable
      show-overflow
      :footer-span-method="footerColspanMethod"
      :footer-method="footerMethod"
      :data="value"
      @checkbox-all="selectAllEvent"
      @checkbox-change="selectChangeEvent"
    >
      <vxe-table-column
        type="checkbox"
        align="center"
        width="40"
      ></vxe-table-column>
      <vxe-table-column
        v-for="(item, k) in columns"
        :key="k"
        :field="item.field"
        align="center"
        width="100"
        :title="item.title"
      ></vxe-table-column>
      <vxe-table-column fixed="right" title="操作" align="center" width="90">
        <template #default="{ rowIndex, row }">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeRow(rowIndex)"
          >
            <el-button class="text-red" slot="reference" type="text"
              >删除</el-button
            >
          </el-popconfirm>
          <el-button slot="reference" type="text" @click="rowedit(rowIndex,row)"
            >编辑</el-button
          >
        </template>
      </vxe-table-column>
    </vxe-table>

    <!-- 分页 -->
    <!-- <vxe-pager
      background
      size="small"
      :current-page="tablePage.currentPage"
      :page-size="tablePage.pageSize"
      :total="tablePage.totalResult"
      :page-sizes="[5, 8]"
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
      @page-change="handlePageChange">
    </vxe-pager> -->
    <UploadModal ref="uploadModal" @onGetFileList="getFileList" />

    <!-- 弹框 -->
    <Modal :modalConfig="modalConfig" @onClose="closeModal">
      <component
        :is="formName"
        ref="modalForm"
        :params="params"
        @close="closeModal"
        @showback="showback"
      />
    </Modal>
  </div>
</template>

<script>
import request from '../../../../utils/request';
import { UploadModal } from '../../../../modules';
import Modal from '../../../../components/modal';
import Form from './form';

export default {
  props: {
    value: Array,
    getValue: Function,
    functionCode: String,
  },
  data() {
    return {
      columns: [], // 标题数组
      selectRow: {},
      // 不同按钮传递的弹框参数
      modalConfig: {
        title: '标题',
        type: 'Modal',
        visible: false,
      },
      params: {}, // 传给下一个表单的参数
      formName: 'Form',
      idx: -1, // 用于编辑完成后删除原来那一条数据
      // // 分页
      // tablePage: {
      //   currentPage: 1,
      //   pageSize: 5,
      //   totalResult: 0
      // }
    };
  },
  components: {
    UploadModal,
    Modal,
    Form,
  },
  created() {
    if (this.functionCode) {
      this.getColumn(this.functionCode);
    }
  },
  methods: {
    // 获取表格配置
    getColumn(functionCode) {
      const parentCode = this.parentCode
        ? this.parentCode
        : this.$store.state.menus.selectMenu;
      request
        .post('/mdm/mdmtableconfig/query', { functionCode, parentCode })
        .then((res) => {
          if (res.success) {
            this.columns = res.result.column.filter((v) => v.visible);
          }
        });
    },
    // 设置单元格的显示内容
    footerMethod({ columns, data }) {
      const footerData = [
        columns.map((column, _columnIndex) => {
          if (_columnIndex === 0) {
            return '总计';
          }
          if (['forecastSalesAmount'].includes(column.property)) {
            return this.calculate(data, 'forecastSalesAmount');
          }
          return null;
        }),
      ];
      return footerData;
    },
    // 合并列
    footerColspanMethod({ _columnIndex }) {
      if (_columnIndex === 0) {
        return {
          rowspan: 1,
          colspan: 2,
        };
      }
      if (_columnIndex === 1) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },
    // 计算总计
    calculate(list, field) {
      let total = 0;
      list.map((v) => {
        total += v.forecastSalesAmount;
        return v;
      });
      return total;
    },
    // // 改变分页
    // handlePageChange ({ currentPage, pageSize }) {
    //   this.tablePage.currentPage = currentPage
    //   this.tablePage.pageSize = pageSize
    // },

    // 选择列表数据
    selectAllEvent({ checked, records }) {
      // console.log(checked ? '所有勾选事件' : '所有取消事件', records);
    },
    selectChangeEvent({ checked, records }) {
      // console.log(checked ? '勾选事件' : '取消事件', records);
    },

    // 打开按不同方式添加活动的表单
    openModal() {
      this.modalConfig.visible = true;
    },
    closeModal() {
      this.modalConfig.visible = false;
    },

    // 新增活动
    addActive(type) {
      const categoriesCode = this.getValue('categoriesCode');
      const endTime = this.getValue('endDate');
      if (!endTime) {
        this.$message.error('请选择活动结束时间');
        return;
      }
      if (!categoriesCode) {
        this.$message.error('请选择活动大类');
        return;
      }
      this.idx = -1; // 防止新增时误删数据
      this.params = {
        formFunctionCode: 'add_active_form', // 页面配置编码，获取顶部下拉框
        endTime, // 活动结束时间
        addType: type, // 活动新增类型
      };

      if (type === 'customer') {
        this.modalConfig.title = '按客户新增';
      } else if (type === 'org') {
        this.modalConfig.title = '按组织新增';
      } else {
        this.modalConfig.title = '按政策新增'; // 按政策新增时选择政策的下拉框数据，选择实用客户和实用组织的弹框table数据？
      }

      this.openModal();
    },

    // 编辑，处理数据把下拉框编码取出来，并把编码对应的名称取出来，并打开弹窗
    rowedit(index, row) {
      this.idx = index;
      const endTime = this.getValue('endDate');
      // 取出编码
      const {
        customerCode, fineCode, orgCode, payType, policyCode,
      } = row;
      const tempobj = {
        customerCode, fineCode, orgCode, payType, policyCode,
      };
      // 取出名称，当新增时没有在下拉框选择时直接赋值
      const {
        fineName, payTypeName, customerName, orgName,
      } = row;
      const tempselectobj = {
        fineName, payTypeName, customerName, orgName,
      };
      tempobj.addActive = [{ ...row }];
      tempobj.tempselectobj = tempselectobj;
      this.params = {
        formFunctionCode: 'add_active_form', // 页面配置编码
        value: tempobj,
        endTime, // 活动结束时间
      };
      if (row.addTypeFlag === 'byCustomer') {
        this.modalConfig.title = '按客户新增';
        this.params.addType = 'customer'; // 活动新增类型
      } else if (row.addTypeFlag === 'byOrg') {
        this.modalConfig.title = '按组织新增';
        this.params.addType = 'org';
      } else {
        this.modalConfig.title = '按政策新增';
        this.params.addType = 'policy';
      }
      this.openModal();
    },

    // 选择活动后传到该组件再传到外层表单
    showback(list) {
      if (this.idx !== -1) {
        this.value.splice(this.idx, 1);
      }
      let temparr = [];
      if (this.value.length !== 0) {
        temparr = this.value.concat(list);
      } else {
        temparr = list;
      }
      this.$emit('input', temparr);
    },

    // 附件上传
    upload() {
      const fileList = this.getValue('fileList');
      this.$refs.uploadModal.openModal(fileList);
    },
    // 清空表单数据
    clearTable() {
      if (this.value.length === 0) {
        return false;
      }
      this.$confirm('确认要清空表格数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$emit('input', []);
      }).catch(() => {});
    },
    // 删除
    removeRow(index) {
      this.value.splice(index, 1);
      this.$emit('input', this.value);
    },

    // 上传列表更新
    getFileList(data) {
      this.setValue({
        fileList: data.map((v) => ({
          ...v,
          fileAddress: v.url,
          objectName: v.fileName,
        })),
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detailed-table {
  .text-red {
    color: #f56c6c !important;
    margin: 0 5px;
  }
}
.el-link {
  margin: 0 10px;
}
</style>
