<template>
  <div class="detailed-table">
    <vxe-toolbar v-if="columns.length > 0">
      <template #buttons>
        <el-link type="primary" icon="el-icon-plus" @click="addFn" v-if='!disabled'>新增行</el-link>
        <el-link type="primary" icon="el-icon-document-copy" @click="copyLine" v-if='!disabled'>复制行</el-link>
        <el-link type="primary" icon="el-icon-share" @click="shareBudget" v-if='!disabled'>分摊</el-link>
        <el-link type="primary" icon="el-icon-files" @click="upload">附件</el-link>
        <el-link class="text-red" icon="el-icon-delete" @click="clearTable" v-if='!disabled'>清空</el-link>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="xTable"
      resizable
      show-overflow
      :data="value"
      :edit-config="{ trigger: 'click', mode: 'cell', activeMethod: activeCellMethod }"
      @edit-disabled="editDisabledEvent"
      @radio-change="radioChangeEvent"
    >
      <vxe-table-column
        v-for="(item, k) in columns"
        :key="k"
        v-bind="itemRender(item)"
      >
        <template #default="{ row }">
          <div>{{ resetText(row, item.field) }}</div>
        </template>
        <template
          v-if="item.field === 'customerCode' || item.field === 'terminalCode' || item.field === 'giftCodes' || item.field === 'productCodes'"
          #edit="{ row, rowIndex }"
        >
          <div v-if="item.field === 'customerCode'">
            <el-select
              v-model="row[item.field]"
              filterable
              remote
              reserve-keyword
              :placeholder="`请选择${item.title}`"
              :remote-method="query => remoteMethod(item.field, query, rowIndex)"
              :loading="$store.state.buttonApilsLoading"
              @change="changeRow(item.field, row, rowIndex)"
            >
              <el-option
                v-for="(v, k) in customerList"
                :key="k"
                :label="v.customerName"
                :value="v.customerCode"
              ></el-option>
            </el-select>
          </div>
          <div v-else-if="item.field === 'terminalCode'">
            <el-select
              v-model="row[item.field]"
              filterable
              remote
              reserve-keyword
              :placeholder="`请选择${item.title}`"
              :remote-method="query => remoteMethod(item.field, query, rowIndex)"
              :loading="$store.state.buttonApilsLoading"
              @change="changeRow(item.field, row, rowIndex)"
            >
              <el-option
                v-for="(v, k) in terminalList"
                :key="k"
                :label="v.terminalName"
                :value="v.terminalCode"
              ></el-option>
            </el-select>
          </div>
          <div v-else-if="item.field === 'giftCodes' || item.field === 'productCodes'">
            <el-select
              v-model="row[item.field]"
              multiple
              :placeholder="`请选择${item.title}`"
              :remote-method="query => remoteMethod(item.field, query, rowIndex)"
              :loading="$store.state.buttonApilsLoading"
              @change="changeRow(item.field, row, rowIndex)"
              collapse-tags
            >
              <el-option
                v-for="(v, k) in productList"
                :key="k"
                :label="v.productName"
                :value="v.productCode"
              ></el-option>
            </el-select>
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column
        fixed="right"
        title="操作"
        align="center"
        width="80"
        v-if="!disabled && columns.length > 0"
      >
        <template #default="{ rowIndex }">
          <el-popconfirm
            title="此操作将永久删除该数据？"
            confirm-button-type="text"
            @confirm="removeRow(rowIndex)"
          >
            <el-button
              class="text-red"
              slot="reference"
              type="text"
              icon="el-icon-delete"
            ></el-button>
          </el-popconfirm>
        </template>
      </vxe-table-column>
    </vxe-table>
    <UploadModal ref="uploadModal" @onGetFileList="getFileList" :disabled="disabled" />
  </div>
</template>

<script>
import request from '../../../../utils/request';
import { UploadModal } from '../../../../modules';

export default {
  props: {
    value: Array,
    disabled: Boolean,
    actType: String,
    getValue: Function,
    setValue: Function,
    hiddenFields: Function,
    functionCode: String,
    categoriesCode: String,
    parentCode: String,
  },
  data() {
    return {
      columns: [],
      fieldList: null,
      fineList: [], // 细类列表
      orgList: [], // 组织列表
      customerList: [], // 客户列表
      terminalList: [], // 门店列表
      materialList: [], // 物料列表
      materialUnitList: [], // 物料单位
      payTypeList: [], // 支付方式
      numberList: ['num', 'applyAmount', 'forecastSalesAmount'], // 数字格式
      selectRow: null,
      productList: [], // 产品列表
    };
  },
  watch: {
    functionCode(val) {
      if (val) {
        this.getColumn(val);
      } else {
        this.columns = [];
      }
    },
    categoriesCode(val) {
      if (val) {
        this.getFineList();
        this.getOrgList();
      } else {
        this.fineList = [];
        this.orgList = [];
      }
    },
  },
  components: {
    UploadModal,
  },
  created() {
    if (this.functionCode) {
      this.getColumn(this.functionCode);
    }

    this.getMaterialList();
    this.getProductList();
  },
  methods: {
    // 新增行
    addFn() {
      if (!this.categoriesCode) {
        this.$message.error('请先选择活动大类');
      } else if (this.fieldList) {
        const budget = this.getValue('budgetControlVos');
        const params = {
          orgCode: budget[0].orgCode,
          orgName: budget[0].orgName,
          customerCode: budget[0].customerCode,
          customerName: budget[0].customerName,
          terminalCode: budget[0].terminalCode,
          terminalName: budget[0].terminalName,
          giftProductList: [],
          normalProductList: [],
        };
        const list = JSON.parse(JSON.stringify(this.value || []));
        list.push({ ...this.fieldList, categoriesCode: this.categoriesCode, ...params });

        this.$emit('input', list);
        this.selectRow = null;
        // if (budget[0].orgCode) {
        //   this.(this.value.length, budget[0].orgCode)
        // }
      }
    },
    // 选择列表数据
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },
    // 复制行
    copyLine() {
      if (!this.selectRow) {
        this.$message.error('请先选一条数据');
      } else {
        const { id, actDetailCode, ...params } = this.selectRow;
        const list = JSON.parse(JSON.stringify(this.value || []));
        list.push(params);
        this.$emit('input', list);
        this.$nextTick(() => {
          this.selectRow = null;
        });
      }
    },
    // 分摊
    shareBudget() {
      const beginDate = this.getValue('beginDate');
      const endDate = this.getValue('endDate');

      if (!this.selectRow) {
        this.$message.error('请先选一条数据');
      } else if (this.selectRow && this.selectRow.feeShareGroupId) {
        this.$message.error('当前活动明细不能再次进行分摊！');
      } else if (this.selectRow && !this.selectRow.applyAmount) {
        this.$message.error('请先输入费用金额！');
      } else if (!beginDate || !endDate) {
        this.$message.error(!beginDate ? '请先选活动开始时间！' : '请先选活动结束时间！');
      } else {
        const {
          _XID, applyAmount, feeShareGroupId, ...rowData
        } = this.selectRow;
        const params = {
          feeShareAmount: Number(applyAmount),
          executeBeginDate: beginDate,
          executeEndDate: endDate,
          feeShareGroupId,
        };

        request.post('/tpm/tpmActController/actDetailFeeShare', params).then((res) => {
          if (res.success) {
            const index = this.$refs.xTable.getRowIndex(this.selectRow);
            const list = JSON.parse(JSON.stringify(this.value || []));
            const newList = list.filter((v, k) => k !== index);

            const data = res.result.map((v) => ({
              ...rowData,
              ...v,
              applyAmount: v.feeShareAmount,
            }));

            this.$emit('input', newList.concat(data));
            this.selectRow = null;
          }
        });
      }
    },
    // 清空表格
    clearTable() {
      if (this.value.length === 0) {
        return false;
      }
      this.$confirm('确认要清空表格数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$emit('input', []);
        this.selectRow = null;
        this.shareListFn([]);
      });
    },
    // 删除
    removeRow(index) {
      const list = JSON.parse(JSON.stringify(this.value || []));
      const newList = list.filter((v, k) => k !== index);
      this.$emit('input', newList);
      this.selectRow = null;
      this.shareListFn(newList);
    },
    // 附件上传
    upload() {
      const fileList = this.getValue('fileList');
      this.$refs.uploadModal.openModal(fileList);
    },
    // 上传列表更新
    getFileList(data) {
      this.setValue({
        fileList: data.map((v) => ({
          ...v,
          fileAddress: v.url,
          objectName: v.fileName,
        })),
      });
    },
    // 获取表格配置
    getColumn(functionCode) {
      const parentCode = this.parentCode ? this.parentCode : this.$store.state.menus.selectMenu;

      request.post('/mdm/mdmtableconfig/query', { functionCode, parentCode }).then((res) => {
        if (res.success) {
          const _fields = {};
          res.result.column.forEach((v) => {
            _fields[v.field] = '';
          });

          this.fieldList = _fields;
          this.columns = res.result.column.filter((v) => v.visible);
        }
      });
    },
    // 单元格渲染
    itemRender(item) {
      const { title, field, type } = item;
      let column = {
        title,
        field,
        minWidth: 150,
      };
      let editRender = null;

      if (field === 'checkTyperadio' || field === 'checkTypecheckbox') {
        column = {
          type: field === 'checkTyperadio' ? 'radio' : 'checkbox',
          width: '36',
          fixed: 'left',
          visible: !this.disabled,
        };
      } else if (!this.disabled && type === 'input') {
        if (this.numberList.includes(field)) {
          editRender = {
            name: 'ElInputNumber',
            props: {
              placeholder: `请输入${title}`,
              clearable: true,
              controls: false,
              min: 0,
              precision: field === 'num' ? 0 : 2,
              value: undefined,
            },
            events: {
              change: ({ row, rowIndex }) => {
                this.changeRow(field, row, rowIndex);
              },
            },
          };
        } else {
          editRender = {
            name: 'ElInput',
            props: {
              placeholder: `请输入${title}`,
              clearable: true,
            },
          };
        }
      } else if (!this.disabled && type === 'select') {
        editRender = { name: 'ElSelect' };

        if (field === 'fineCode' || field === 'orgCode' || field === 'materialCode') {
          editRender = {
            ...editRender,
            props: {
              placeholder: `请选择${title}`,
              clearable: true,
              filterable: true,
              remote: true,
              remoteMethod: (query) => this.remoteMethod(field, query),
            },
            loading: this.$store.state.buttonApilsLoading,
            events: {
              change: ({ row, rowIndex }) => {
                this.changeRow(field, row, rowIndex);
              },
            },
          };

          if (field === 'fineCode') {
            editRender.options = this.fineList;
            editRender.optionProps = {
              label: 'fineName',
              value: 'fineCode',
            };
          } else if (field === 'orgCode') {
            editRender.options = this.orgList;
            editRender.optionProps = {
              label: 'orgName',
              value: 'orgCode',
            };
          } else {
            editRender.options = this.materialList;
            editRender.optionProps = {
              label: 'materialName',
              value: 'materialCode',
            };
          }
        } else if (field === 'payType') {
          editRender.options = this.payTypeList;
          editRender.optionProps = {
            label: 'dictKey',
            value: 'dictValue',
          };
          editRender.events = {
            change: ({ row, rowIndex }) => {
              this.changeRow(field, row, rowIndex);
            },
          };
        } else if (field === 'materialUnit') {
          editRender.options = this.materialUnitList;
          editRender.optionProps = {
            label: 'unitName',
            value: 'unit',
          };
          editRender.events = {
            change: ({ row, rowIndex }) => {
              this.changeRow(field, row, rowIndex);
            },
          };
        } else if (field === 'giftCodes' || field === 'productCodes') {
          column.minWidth = 230;
        }
      }
      return {
        ...column,
        editRender,
      };
    },
    // 渲染回显
    resetText(row, field) {
      let text = '';
      if (this.numberList.includes(field)) {
        text = row[field] ? row[field].toFixed(2) : '';
      } else if (field === 'fineCode') {
        text = row.fineName || '';
      } else if (field === 'orgCode') {
        text = row.orgName || '';
      } else if (field === 'customerCode') {
        text = row.customerName || '';
      } else if (field === 'terminalCode') {
        text = row.terminalName || '';
      } else if (field === 'materialCode') {
        text = row.materialName || '';
      } else if (field === 'payType') {
        text = row.payTypeName;
      } else if (field === 'materialUnit') {
        text = row.materialUnitName || '';
      } else if (field === 'giftCodes') {
        text = row.giftProductList.map((v) => v.productName).join(',') || '';
      } else if (field === 'productCodes') {
        text = row.normalProductList.map((v) => v.productName).join(',') || '';
      } else {
        text = row[field];
      }

      return text;
    },
    // 单元格是否允许编辑
    activeCellMethod({ column, row, rowIndex }) {
      if (column.property === 'customerCode') {
        if (!row.orgCode) {
          return false;
        }
        this.getCustomerList(rowIndex);
      }

      if (column.property === 'terminalCode') {
        if (!row.customerCode) {
          return false;
        }

        this.getSubTerminalList(rowIndex);
      }

      if (column.property === 'payType') {
        if (!row.fineCode) {
          return false;
        }

        this.payTypeList = row.payTypes;
      }

      if (column.property === 'materialUnit') {
        if (!row.materialCode) {
          return false;
        }

        this.materialUnitList = row.unitList;
      }

      return true;
    },
    editDisabledEvent({ column }) {
      if (column.property === 'customerCode') {
        this.$message.error('请先选择组织');
      } else if (column.property === 'terminalCode') {
        this.$message.error('请先选择客户');
      } else if (column.property === 'payType') {
        this.$message.error('请先选择活动细类');
      } else if (column.property === 'materialUnit') {
        this.$message.error('请先选择物料');
      }
    },
    // 远程搜索
    remoteMethod(field, query, index) {
      if (field === 'fineCode') {
        this.getFineList(query);
      } else if (field === 'orgCode') {
        this.getOrgList(query);
      } else if (field === 'customerCode') {
        this.getCustomerList(index, query);
      } else if (field === 'terminalCode') {
        this.getSubTerminalList(index, query);
      } else if (field === 'giftCodes' || field === 'productCodes') {
        this.getProductList(query);
      }
    },
    // 编辑监听
    changeRow(field, row, index) {
      const list = JSON.parse(JSON.stringify(this.value));
      let _item = null;

      switch (field) {
        case 'fineCode':
          _item = this.fineList.find((v) => v.fineCode === row.fineCode);
          list[index] = {
            ...list[index],
            fineName: _item ? _item.fineName : '',
            isShareToProduct: _item ? _item.isShareToProduct : '',
            payType: '',
            payTypeName: '',
            payTypes: _item ? _item.payTypes || [] : [],
          };

          this.shareListFn(list);
          break;
        case 'orgCode':
          _item = this.orgList.find((v) => v.orgCode === row.orgCode);

          list[index] = {
            ...list[index],
            orgName: _item ? _item.orgName : '',
            customerCode: '',
            customerName: '',
            terminalCode: '',
            terminalName: '',
          };
          break;
        case 'customerCode':
          _item = this.customerList.find((v) => v.customerCode === row.customerCode);

          list[index] = {
            ...list[index],
            customerName: _item ? _item.customerName : '',
            terminalCode: '',
            terminalName: '',
          };
          break;
        case 'terminalCode':
          _item = this.terminalList.find((v) => v.terminalCode === row.terminalCode);

          list[index] = {
            ...list[index],
            terminalName: _item ? _item.terminalName : '',
          };
          break;
        case 'payType':
          _item = row.payTypes.find((v) => v.dictValue === row.payType);

          list[index] = {
            ...list[index],
            payTypeName: _item ? _item.dictKey : '',
          };
          break;
        case 'materialCode':
          _item = this.materialList.find((v) => v.materialCode === row.materialCode);

          list[index] = {
            ...list[index],
            materialName: _item ? _item.materialName : '',
            materialUnit: '',
            materialUnitName: '',
            materialPrice: '',
            applyAmount: '',
            unitList: _item ? _item.mdmMaterialUnitRespVos : [],
          };
          break;
        case 'materialUnit':
          _item = row.unitList.find((v) => v.unit === row.materialUnit);

          list[index] = {
            ...list[index],
            materialUnitName: _item ? _item.unitName : '',
            materialPrice: _item ? _item.costPrice : '',
            applyAmount: _item ? Number(row.num) * _item.costPrice : '',
          };
          break;
        case 'applyAmount':
          list[index] = {
            ...list[index],
            ext1: this.countFn(row),
          };
          break;
        case 'num':
          list[index] = {
            ...list[index],
            ext1: this.countFn(row),
          };
          break;
        case 'giftCodes':
          _item = this.productList.filter((v) => row.giftCodes.includes(v.productCode));

          list[index] = {
            ...list[index],
            giftProductList: _item.map((v) => {
              const { id, ...params } = v;
              return params;
            }),
          };
          break;
        case 'normalProductCodes':
          _item = this.productList.filter((v) => row.normalProductCodes.includes(v.productCode));

          list[index] = {
            ...list[index],
            normalProductList: _item.map((v) => {
              const { id, ...params } = v;
              return params;
            }),
          };
          break;
        default:
          break;
      }

      this.$emit('input', list);
    },
    // 计算单箱费用
    countFn(row) {
      const { num, applyAmount } = row;
      let countNum = 0;

      if (num && applyAmount) {
        countNum = (Number(applyAmount) / Number(num)).toFixed(2);
      }

      return countNum;
    },
    // 分摊信息设置
    shareListFn(list) {
      const state = list.map((v) => v.isShareToProduct).includes('Y');
      this.hiddenFields(!state, ['t3', 'feeShareVos']);

      if (!state) {
        this.setValue({ feeShareVos: [] });
      }
    },
    // 获取细类列表
    getFineList(query) {
      if (this.categoriesCode) {
        const param = {
          categoriesCode: this.categoriesCode,
          actType: this.actType,
        };
        // 模糊查询
        if (query) {
          param.fineCodeOrName = query;
        }

        request.post('/tpm/tpmActController/getCategoryFinesByCategoryCode', param).then((res) => {
          if (res.success) {
            this.fineList = res.result || [];
          }
        });
      }
    },
    // 获取组织列表
    getOrgList(query) {
      const controlIds = this.getValue('controlIds');
      if (controlIds) {
        const param = {
          controlIds,
        };
        // 模糊查询
        if (query) {
          param.orgCodeOrName = query;
        }

        request.post('/tpm/tpmActController/getOrgInfoByControlIds', param).then((res) => {
          if (res.success) {
            this.orgList = res.result || [];
          }
        });
      }
    },
    // 获取客户列表
    getCustomerList(index, query) {
      const { orgCode } = this.value[index];

      if (orgCode) {
        const param = {
          orgCode,
        };
        // 模糊查询
        if (query) {
          param.customerCodeOrName = query;
        }
        request.post('/tpm/tpmActController/getCustomerMsgByOrgCodes', param).then((res) => {
          if (res.success) {
            this.customerList = res.result || [];
          }
        });
      }
    },
    // 门店
    getSubTerminalList(index, query) {
      const { customerCode } = this.value[index];
      if (customerCode) {
        const param = {
          customerCodeList: [customerCode],
        };

        // 模糊查询
        if (query) {
          param.codeOrName = query;
        }
        request.post('/mdm/mdmTerminalController/customerTerminalList', param).then((res) => {
          if (res.success) {
            this.terminalList = res.result || [];
          }
        });
      }
    },
    // 物料
    getMaterialList(query) {
      const param = {
        enableStatus: '009',
      };
      // 模糊查询
      if (query) {
        param.materialCodeOrName = query;
      }

      request.post('/tpm/tpmActController/getMaterialList', param).then((res) => {
        if (res.success) {
          this.materialList = res.result || [];
        }
      });
    },
    // 产品
    getProductList(query) {
      const param = {
        enableStatus: '009',
      };
      // 模糊查询
      if (query) {
        param.productName = query;
      }

      request.post('/mdm/mdmProductController/productSelectList', param).then((res) => {
        if (res.success) {
          this.productList = res.result || [];
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.detailed-table {
  padding-left: 50px;

  .text-red {
    color: #f56c6c !important;
  }
}
</style>
