var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "share-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addFn },
                        },
                        [_vm._v("新增行")]
                      ),
                      _c(
                        "el-link",
                        {
                          staticClass: "text-red",
                          on: { click: _vm.clearTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1944966770
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            resizable: "",
            "show-overflow": "",
            data: _vm.value,
            "edit-config": { trigger: "click", mode: "cell" },
          },
        },
        [
          _vm._l(_vm.columns, function (item, k) {
            return _c(
              "vxe-table-column",
              _vm._b(
                { key: k, attrs: { "min-width": 120 } },
                "vxe-table-column",
                _vm.itemRender(item, k),
                false
              )
            )
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: { title: "操作", align: "center", width: "80" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                title: "此操作将永久删除该数据？",
                                "confirm-button-type": "text",
                              },
                              on: {
                                confirm: function ($event) {
                                  return _vm.removeRow(rowIndex)
                                },
                              },
                            },
                            [
                              _c("el-button", {
                                staticClass: "text-red",
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  icon: "el-icon-delete",
                                },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3242369890
                ),
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }